﻿window.Deposits = {
    fieldUpdated: function fieldUpdated(key)
    {
        //Key is passed in, update the deposit type line differences
        if (key !== '' && key !== undefined){
            let depositId = "di_" + key;
            let gsrId = "gi_" + key;
            let differenceId = "ci_" + key;
            let deposit = document.getElementById(depositId).value;
            let gsr = document.getElementById(gsrId).value;
            let diff = document.getElementById(differenceId);


            if (deposit !== undefined && gsr !== undefined) {
                diff.value = (deposit - gsr).toString()
            }
        }

        let submit = document.getElementById('submitRow');
        let validAmounts = Deposits.checkTotalAmounts();
        Deposits.checkRecordDate().then(validDate => {
                if (validDate && validAmounts) {
                    submit.classList.remove('visually-hidden');
                } else {
                    submit.classList.add('visually-hidden');
                }
        }).catch(error => {
            submit.classList.add('visually-hidden');
        });
        
    },

    /*nextAvailableRecordDate: function nextAvailableRecordDate() {
        let location = document.getElementById('locationSelect').value;

        let request = `/Form/GetNextAvailableRecordDateForLocation/${location}`
        $.ajax({
            url: request,      // The URL to which the request is sent
            type: 'GET',                  // The HTTP method to use for the request
            contentType: 'application/json', // The content type of the request
            success: function (response) {
                
            },
            error: function (jqXHR, textStatus, errorThrown) {
            }
        });
    },*/
    
    checkRecordDate: function checkRecordDate() {
        return new Promise((resolve, reject) => {
            let location = document.getElementById('locationSelect').value;
            let date = document.getElementById('recordDate').value;
            let recordInputLabel = document.getElementById('record');
            let message = document.getElementById('invalidMessage');
            let id = document.getElementById('existingId').value;
            let request = `/Form/CheckRecordDateAvailabilityForLocation/${location}/${date}/${id}`
            $.ajax({
                url: request,      // The URL to which the request is sent
                type: 'GET',                  // The HTTP method to use for the request
                contentType: 'application/json', // The content type of the request
                success: function (response) {
                    if (response === '') {
                        recordInputLabel.classList.remove('text-danger');
                        recordInputLabel.classList.add('text-success');
                        message.classList.add('visually-hidden');

                        resolve(true);
                    } else {
                        recordInputLabel.classList.remove('text-success');
                        recordInputLabel.classList.add('text-danger');
                        message.innerText = response;
                        message.classList.remove('visually-hidden');

                        resolve(false);  
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    recordInputLabel.classList.add('text-danger');
                    message.innerText = errorThrown;
                    message.classList.remove('visually-hidden');

                    reject(false);
                }
            });
        });
    },
    
    checkTotalAmounts: function checkTotalAmounts()
    {
        let totalAmount = Number.parseFloat(document.getElementById('amountInput').value);
        let depositTypes = document.getElementsByClassName('depositAmount');
        let totalDiff = document.getElementById('totalDiffUpperInput');
        let totalDiffUpper = document.getElementById('totalDiffUpper');
        let totalDiffLowerInput = document.getElementById('totalDiffLowerInput');
        let totalDiffLower = document.getElementById('totalDiffLower');
        let invalidMessageDiff = document.getElementById('invalidMessageDiff');

        let depositTypesTotal = 0;
        for (const depositTypesKey in depositTypes) {
            let el = depositTypes[depositTypesKey]
            if(el.value !== undefined){
                depositTypesTotal += Number.parseFloat(el.value);
            }
        }

        
        if (totalAmount !== undefined){
            let totalDiffVal = (totalAmount - depositTypesTotal).toFixed(2).toString();
            totalDiff.value = totalDiffVal
            totalDiffLowerInput.value = totalDiffVal
            
            if (totalAmount === depositTypesTotal){

                totalDiffUpper.classList.remove('text-danger')
                totalDiffUpper.classList.remove('fw-bold')
                totalDiffLower.classList.remove('text-danger')
                totalDiffLower.classList.remove('fw-bold')
                invalidMessageDiff.classList.add('visually-hidden')

                return true;
            }
            else{
                totalDiffUpper.classList.add('text-danger')
                totalDiffUpper.classList.add('fw-bold')
                totalDiffLower.classList.add('text-danger')
                totalDiffLower.classList.add('fw-bold')
                invalidMessageDiff.classList.remove('visually-hidden')

                return false;
            }
        }
    }
}
